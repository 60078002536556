.c-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 20px 30px;
  align-items: center;
  max-width: 100vw;

  &__logo {
    grid-column: 1/2;
  }

  &__menu {
    grid-column: 2/3;
    justify-self: center;
  }

  &__contact {
    grid-column: 3/4;
  }

  @media (max-width: $tablet-screen) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content;

    &__logo {
      grid-column: 1/2;
    }

    &__menu {
      grid-column: 2/3;
      justify-self: end;
    }

    &__contact {
      grid-column: 2/3;
      grid-row: 2/3;
      justify-self: end;
    }
  }
}
