@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@700;900&family=Inter&family=Tiro+Kannada&display=swap');

$dark-color: #202020;
$light-color: #FFFFFF;
$active-light: #5B38E3;
$active-dark: #F7D046;

$principal-font: 'Darker Grotesque', sans-serif;
$text-font: 'Inter', sans-serif;
$name-font: 'Tiro Kannada', serif;

$laptop-screen: 1400px;
$tablet-screen: 900px;
$mobile-screen: 640px;