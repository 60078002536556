.c-icon {
  &__javascript path {
    fill: #f1de4f !important;
  }
  
  &__react path {
    fill: #62d4fa !important;
  }
  
  &__html path {
    fill: #de6e3c !important;
  }
  
  &__css path {
    fill: #53a7dc !important;
  }
  
  &__sass path {
    fill: #bf6b97 !important;
  }
  
  &__node path {
    fill: #54b689 !important;
  }

  &__strapi path {
    fill: #8e75ff !important;
  }

}
