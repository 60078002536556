.c-skills {
  text-align: center;

  &__icons-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(
      7,
      minmax(auto, 1fr)
    );

    @media (max-width: $mobile-screen) {
      grid-template-columns: repeat(2,minmax(auto, 1fr));
    }
  }

  &__title {
    font-family: $principal-font;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__item {
    text-align: center;
  }
  &__icon svg {
    height: 50px;
  }

  &__label {
    font-family: $principal-font;
    color: darken($light-color, 10%);
    font-size: 1.1rem;
    margin-top: 0.2rem;
  }
}
