/* This setting will reset all initial values */

* { margin: 0; padding: 0; }

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
  list-style: none;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
 display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
 - fix for the content editable attribute will work properly. */
:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

*,
*::after,
*::before {

    padding: 0;
    margin: 0;
    outline: 0px solid;
    box-sizing: border-box; 

    &:hover,
    &:active,
    &:focus, 
    &:visited{

        outline: 0px solid;

    }

}