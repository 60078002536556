.c-experience {
  background: linear-gradient(306deg, #cbbefd, #ffffff);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 17s ease infinite;
  -moz-animation: AnimationName 17s ease infinite;
  -o-animation: AnimationName 17s ease infinite;
  animation: AnimationName 17s ease infinite;

  @-webkit-keyframes AnimationName {
      0%{background-position:0% 65%}
      50%{background-position:100% 36%}
      100%{background-position:0% 65%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:0% 65%}
      50%{background-position:100% 36%}
      100%{background-position:0% 65%}
  }
  @-o-keyframes AnimationName {
      0%{background-position:0% 65%}
      50%{background-position:100% 36%}
      100%{background-position:0% 65%}
  }
  @keyframes AnimationName {
      0%{background-position:0% 65%}
      50%{background-position:100% 36%}
      100%{background-position:0% 65%}
  }


  color: $dark-color;
  display: grid;
  font-family: $text-font;
  justify-items: center;
  padding: 2.5rem 4rem;
  row-gap: 2rem;

  @media (max-width: $tablet-screen) {
    padding: 2.5rem 1rem;

  }

  &__title {
    font-family: $principal-font;
    font-size: 2rem;
    //600;
  }

  &__description {
    color: darken($light-color, 10%);
    line-height: 1.5rem;
    text-align: center;
    width: 80%;
    @media (max-width: $mobile-screen) {
      width: 95%;
    }
  }

  &__stats {
    display: grid;
    column-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $mobile-screen) {
      grid-template-columns: 1fr;
    }
  }

}