.c-contact-cta {

  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(5, min-content);

  &__item {
    font-size: 1.3rem;

    svg:hover {
      color: $active-light;
    }
  }
  
}